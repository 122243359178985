<template>
  <div v-if="$trip.places"
       class="popup-audio-guide">
    <div class="popup-audio-guide__wrapper">
      <Column :class="$viewport.desktop ? 'grid-6' : ''"
              :full="$viewport.mobile">
        <p class="popup-audio-guide__title">Аудиогид по маршруту {{$trip.title}}</p>
        <p class="popup-audio-guide__subtitle">Прослушайте аудиогид, который подготовили наши
          путешественники</p>
        <div v-for="(place, index) in $trip.places"
             :key="place.id">
          <p class="popup-audio-trips__title" v-if="place.audios.length > 0">
            {{`${index + 1}. ${place.title}`}}
          </p>
          <AudioPlayer v-for="audio in place.audios"
                       class="popup-audio-guide__player"
                       :key="audio.id"
                       :params="audio"/>
        </div>
      </Column>
    </div>
  </div>
  <Loader v-else/>
</template>

<script>
import AudioPlayer from '../../AudioPlayer.vue';

export default {
  name: 'PopupAudioGuide',
  components: { AudioPlayer },
};
</script>
